/* Header.css */

.navbar {
  background-color: #3b87d3;
}

.navbar-brand {
  font-size: 24px;
  font-weight: bold;
}

.navbar-nav .nav-link {
  color: #212529;
  font-size: 16px;
  font-weight: bold;
}

.navbar-nav .nav-link:hover {
  color: #007bff;
}

.dropdown-menu .dropdown-item {
  font-size: 16px;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f8f9fa;
}

.dropdown-menu {
  border: none;
}

.dropdown-menu .nav-link {
  color: #212529;
  font-size: 16px;
  font-weight: bold;
}

.dropdown-menu .nav-link:hover {
  color: #007bff;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .navbar-nav {
    flex-direction: column;
  }

  .navbar-nav  {
    order: 1; /* Moves the cart link to the bottom */
    margin-left: auto; /* Aligns the cart link to the right */
  }
}
