.cart-page {
  margin-top: 64px;
  margin-bottom: 20px;
}

.cart-page h1 {
  padding: 15px !important;
  font-family: "roboto", sans-serif;
  font-weight: normal;
  background-color: rgba(0, 0, 255, 0.072) !important;
}

.cart-page h1 > p {
  font-size: 20px;
  margin-top: 10px;
}

.cart-page .card {
  padding: 5px !important;
  height: 150px !important;
  margin-bottom: 5px !important;
}

.cart-page .cart-remove-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-page .cart-summary {
  text-align: center;
}

/* Media Query for Small Screens */
@media screen and (max-width: 768px) {
  .cart-page h1 {
    padding: 10px !important;
    font-size: 1.5em; /* Adjust font size for smaller screens */
  }

  .cart-page h1 > p {
    font-size: 16px; /* Adjust font size for smaller screens */
  }

  .cart-page .card {
    height: auto !important; /* Let the height adjust as per content */
  }

  .cart-page .cart-summary {
    margin-top: -100px !important; /* Adjust the margin-top for smaller screens */
  }
  .cart-page .card-img-top {
    width: 200px;
    height: 200px;
  }
}
