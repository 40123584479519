/* Homepage.css */

/* General Styles */
.banner-img {
  margin-top: 60px;
}

.home-page h1 {
  color: gray;
  font-family: "Playfair Display", serif;
}

.home-page {
  display: flex;
  flex-wrap: wrap;
}

/* Product Cards */
.home-page .card {
  background-color: rgba(254, 254, 254, 0.097);
  width: 18rem;
  /* border-radius: none; */
}

.home-page .card-name-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.home-page .card-price {
  color: #FF4500;
  font-weight: bold;
}

.home-page button {
  /* border-radius: 10px; */
  width: 100%;
  /* border-top-left-radius: 5px; */
  /* border-bottom-right-radius: 5px; */
  font-size: 14px;
  font-weight: bold;
}

.home-page .card-text {
  color: rgb(90, 89, 89);
  margin-right: 0px;
}

.home-page .card img:hover {
  transform: scale(0.9);
}

.home-page .card-img-top {
  height: 300px !important;
}

/* Filters */
.filters h4 {
  margin-top: 100px;
  color: gray !important;
  border-bottom: 1px solid rgba(128, 128, 128, 0.337);
}

.ant-checkbox-wrapper:first-of-type {
  margin-left: 8px;
}

.ant-checkbox-wrapper {
  margin: 2px;
}

.ant-radio-wrapper {
  margin-bottom: 5px !important;
  margin-left: 10px;
}

.filters button {
  background-color: black;
  width: 100%;
  border: none;
  border-radius: 0;
  margin-top: 20px;
}

/* Load More Button */
.loadmore {
  color: green;
  font-weight: bold;
  font-size: 20px !important;
}

.home-page .btn-more{
  margin-right: 2px;
  color: white;
  background-color: black;
}

.home-page .btn-add{
  margin-left: 2px;
  background:#04AA6D;
}

/* For small screen sizes */
@media screen and (max-width: 768px) {
  .home-page .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0px;
  }
  
  .home-page .card {
    width: 50%; /* Set the width to 50% minus margins to fit two cards in a row */
    margin: 0px 0px 0px; /* Add some margin around each card */
    border-radius: 10px;
    margin-top: 10px;

  }

  .home-page .card-img-top {
    height: 150px !important; /* Adjust the height of the image */
    width: 150px;
    object-fit: cover; /* Ensure the image covers the entire container */
  }

  .home-page .card-title {
    font-size: 15px; /* Adjust the font size of the card titles for smaller screens */
    /* margin-right: 15px; */
    word-wrap: break-word;
    display: block;

  }
  
  .home-page .card-price {
    font-size: 14px; /* Adjust the font size of the card prices for smaller screens */
    word-wrap: break-word;
    display: block;
    /* margin-top: 15px; */
    margin-left: 10px;
  }
  .home-page .btn-more{
    height:30px;
    width: 200px;
    background-color: black;
    font-size: 10px;
  }

  .home-page .btn-add{
    height: 30px;
    width: 200px;
    background: #ff943c;
    border: none;
    font-size: 10px;
  }
}

